import React from 'react';
import { Col } from 'reactstrap';
// import './references.scss';

function References() {
  return (
    <Col>
      <div className="references-wrapper">
        <h3>References</h3>
        <ol>
          <li> {/*#1*/}
            Gender Studies Departments on Solidarity with Palestinian Feminist Collective: &nbsp;
            <a href="http://genderstudiespalestinesolidarity.weebly.com/"
            target="_blank" rel="noopener noreferrer">
              http://genderstudiespalestinesolidarity.weebly.com/
            </a>
          </li>
          <li> {/*#2*/}
            USC Open Letter (2020): &nbsp;
            <a href="https://usc-faaz.org"
            target="_blank" rel="noopener noreferrer">
              https://usc-faaz.org
            </a>
          </li>
          <li> {/*#3*/}
            AEN Section for Women Faculty Response to the "Gender Studies Departments in Solidarity with Palestinian Feminist Collective" Statement: &nbsp;
            <a href="https://academicengagement.org/wp-content/uploads/2021/05/AEN-Section-for-Women-Faculty-Response-to-the-Palestinian-Feminist-Collective-Solidarity-Statement.pdf"
               target="_blank" rel="noopener noreferrer">
              https://academicengagement.org/wp-content/uploads/2021/05/AEN-Section-for-Women-Faculty-Response-to-the-Palestinian-Feminist-Collective-Solidarity-Statement.pdf
            </a>
          </li>
          <li> {/*#4*/}
            AEN Statement on Incendiary Communications Regarding the Israeli-Palestinian Conflict by Faculty and Academic Departments: &nbsp;
            <a href="https://academicengagement.org/wp-content/uploads/2021/06/AEN-Statement-on-Anti-Israel-Communications-from-Academic-Departments-6.2.21.pdf"
            target="_blank" rel="noopener noreferrer">
              https://academicengagement.org/wp-content/uploads/2021/06/AEN-Statement-on-Anti-Israel-Communications-from-Academic-Departments-6.2.21.pdf
            </a>
          </li>
          <li> {/*#5*/}
            "Statements by departments about the Gaza war have implications for academic freedom", OpEd in Inside Higher Ed by Cary Nelson: &nbsp;
            <a href="https://www.insidehighered.com/views/2021/06/03/statements-departments-about-gaza-war-have-implications-academic-freedom-opinion"
               target="_blank" rel="noopener noreferrer">
              https://www.insidehighered.com/views/2021/06/03/statements-departments-about-gaza-war-have-implications-academic-freedom-opinion
            </a>
          </li>
          <li> {/*#6*/}
            Database of Antisemitic Incidents Worldwide Since Outbreak of Israel-Gaza Violence: &nbsp;
            <a href="https://combatantisemitism.org/latest-news/database-of-antisemitic-incidents-worldwide-since-outbreak-of-israel-gaza-violence/"
               target="_blank" rel="noopener noreferrer">
              https://combatantisemitism.org/latest-news/database-of-antisemitic-incidents-worldwide-since-outbreak-of-israel-gaza-violence/
            </a>
          </li>
        </ol>
      </div>
    </Col>
  );
}

export default References;