import React from 'react';
import { Col } from 'reactstrap';

import './header.scss';

function Header() {
  return (
    <Col>
      <div className="header-wrapper">
          &nbsp;
      </div>
    </Col>
  );
}

export default Header;