import React from 'react';
import { Col } from 'reactstrap';

function Reactions() {
  return (
    <Col>
      <div className="reactions-wrapper">
        <h2>Reactions</h2>
        <ul>
          <li>
            <a href="FacultyLetter_OCR_080122.pdf">Letter from President Folt</a> (August 1, 2022).
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/federal-probe-investigates-usc-for-anti-semitic-environment-against-campus-jewish-leader/"
            target="_blank"
            rel="noopener noreferrer">Federal probe investigates USC for anti-Semitic environment against campus Jewish leader</a> (July 27, 2022)
          </li>
          <li>
            The Inside Higher Ed
            article: <a
            href="https://www.insidehighered.com/news/2022/07/27/ed-launches-title-vi-investigation-usc?utm_source=Inside+Higher+Ed&utm_campaign=72098ee621-DNU_2021_COPY_02&utm_medium=email&utm_term=0_1fcbc04421-72098ee621-237003801&mc_cid=72098ee621&mc_eid=fc60cbb888"
            target="_blank"
            rel="noopener noreferrer">USC Faces Investigation Over Alleged Student Harassment</a> (July 27, 2022)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/news/350269/education-dept-to-investigate-uscs-handling-of-antisemitic-harassment-against-rose-ritch/"
            target="_blank"
            rel="noopener noreferrer">Handling of Antisemitic Harassment Against Rose Ritch</a> (July 26, 2022)
          </li>
          <li>
            The Algemeiner
            article: <a
            href="https://www.algemeiner.com/2022/07/26/university-of-southern-california-faces-federal-probe-over-harassment-of-pro-israel-student-leader/"
            target="_blank"
            rel="noopener noreferrer">University of Southern California Faces Federal Probe Over Harassment of Pro-Israel Student Leader</a> (July 26, 2022)
          </li>
          <li>
            The Jewish Insider
            article: <a
            href="https://jewishinsider.com/2022/07/department-of-education-to-open-investigation-into-usc-over-antisemitism-allegations/"
            target="_blank"
            rel="noopener noreferrer">Department of Education to open investigation into USC over antisemitism allegations</a> (July 26, 2022)
          </li>
          <li>
            The Times of Israel
            article: <a
            href="https://www.timesofisrael.com/federal-probe-opened-into-alleged-antisemitism-at-university-of-southern-california/"
            target="_blank"
            rel="noopener noreferrer">Federal probe opened into alleged antisemitism at University of Southern California</a> (July 26, 2022)
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1503967475519557634"
               target="_blank" rel="noopener noreferrer">Judea Pearl's tweet</a> (tweeted on March 16, 2022)
          </li>
          <li>
            The Daily Trojan
            article: <a
            href="https://dailytrojan.com/2022/01/25/tweets-letters-stir-anger/"
            target="_blank"
            rel="noopener noreferrer">"Tweets, letters stir anger"</a> (January 25, 2022)
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/usc-administrations-response-to-anti-semitism-comes-under-the-microscope/"
            target="_blank"
            rel="noopener noreferrer">"USC administration’s response to anti-Semitism comes under the microscope"</a> (December 30, 2021)
          </li>
          <li>
            Our Letter is mentioned in an <a href="https://usc-faaz-12-2021.org" target="_blank" rel="noopener noreferrer">Open Letter calling on USC's leadership to explicitly
            rebuke open expressions of  anti-Semitism and Zionophobia on USC campus</a> (December 1, 2021).
          </li>
          <li>
            Our Letter is mentioned in <a href="https://forward.com/news/476453/as-faculties-push-petitions-critical-of-israel-professors-push-back/" target="_blank" rel="noopener noreferrer">"As faculties push petitions critical of Israel, professors push back"</a> article
            by R. Hale in the Forward magazine (October 7)
          </li>
          <li>
            <a href="210816-response-to-open-letter.pdf" target="_blank" rel="noopener noreferrer">Reply from Provost Zukoski</a> (August 16).
          </li>
          <li>
            The Jewish Journal article <a href="https://jewishjournal.com/news/339587/usc-faculty-call-on-university-to-condemn-department-of-gender-and-sexuality-studies-for-endorsing-anti-israel-statement/" target="_blank" rel="noopener noreferrer">"USC Faculty Call
            on University to Condemn Department of Gender and Sexuality Studies for Endorsing Anti-Israel Statement"</a> discussed the Open Letter (August 10).
          </li>
          <li>
            Jerry Coyne mentioned  the Open Letter in his blog post on the subject of harmful political statements from
            university departments <a href="https://whyevolutionistrue.com/2021/08/10/more-histrionic-and-harmful-political-statements-from-university-departments/" target="_blank" rel="noopener noreferrer">"More histrionic
              and harmful political statements from university departments"</a> (August 10).
          </li>
          <li>
            The Algemeiner article <a href="https://www.algemeiner.com/2021/08/09/usc-faculty-group-decries-cruelly-alienating-statement-on-israel-endorsed-by-department/" target="_blank" rel="noopener noreferrer">"USC Faculty
            Group Decries 'Cruelly Alienating' Statement on Israel Endorsed by Department"</a> discussed the Open Letter (August 9).
          </li>
          <li>
            Jewish News Syndicate brief <a href="https://www.jns.org/usc-faculty-urge-leadership-to-take-action-against-departments-anti-israel-statement/" target="_blank" rel="noopener noreferrer">"USC faculty urge
            leadership to act against department's anti-Israel statement"</a> reported on the Letter (August 9).
          </li>
        </ul>
      </div>
    </Col>
  );
}

export default Reactions;
