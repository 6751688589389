import React from 'react';
import ShareLinks from '../ShareLinks/share';
import { Col } from 'reactstrap';
import './petition.scss';

function Petition() {
  return (
    <Col>
      <div className="petition-wrapper">
        <h1>An Open Letter to the Leadership of USC</h1>
        <p className="date-wrapper">
          <span className="date">
            August 8, 2021
          </span>
        </p>
        <ShareLinks/>
        <p>
          Dear President Folt, Provost Zukoski, and Chairman Caruso,
        </p>
        <p>
          We, the undersigned faculty of USC, write to express our objection and dismay regarding an inflammatory and
          misleading  <a href="http://genderstudiespalestinesolidarity.weebly.com/" target="_blank" rel="noopener noreferrer">online "Statement"</a> that
          has been publicly and officially endorsed by USC’s Department of Gender
          and Sexuality Studies [1]. The Statement contains numerous distortions of vital factual information concerning
          the historical and contemporary reality in Israel/Palestine. Although, as a group, we hold wide and diverse
          perspectives on the Israeli-Palestinian conflict and related issues, we all believe that the errors and
          polemic tone of this Statement are cruelly alienating to many students, staff, and faculty, who are members
          of the USC community. It is also particularly troubling that the Statement was endorsed by a USC department
          on behalf of all its constituents.
        </p>
        <p>
          Nearly a year ago several of us signed <a href="https://usc-faaz.org" target="_blank" rel="noopener noreferrer">an Open Letter</a> protesting
          the personal attacks against Rose Ritch, an undergraduate student who felt compelled to resign her post as a
          Vice-President of USC's Undergraduate Student Government because of her Jewish-Zionist identity [2]. Now, we
          see similar hateful political attacks supported by an entire USC department. This
          Statement <a href="https://academicengagement.org/wp-content/uploads/2021/05/AEN-Section-for-Women-Faculty-Response-to-the-Palestinian-Feminist-Collective-Solidarity-Statement.pdf" target="_blank" rel="noopener noreferrer">has been refuted</a> already
          by many [3], but it is still supported by USC's Department of Gender and Sexuality Studies.
        </p>
        <p>
          We do not know whether such departmental declarations of political support are legal, but they are
          certainly <a href="https://academicengagement.org/wp-content/uploads/2021/06/AEN-Statement-on-Anti-Israel-Communications-from-Academic-Departments-6.2.21.pdf"
                       target="_blank" rel="noopener noreferrer">unethical</a> [4].  <a href="https://www.insidehighered.com/views/2021/06/03/statements-departments-about-gaza-war-have-implications-academic-freedom-opinion"
          target="_blank" rel="noopener noreferrer">They have nothing to do with freedom of speech of individuals; rather, they fall under compelled
          speech</a> [5] because they appear to speak on behalf of all members of the department (e.g. faculty, staff, and
          students), many of whom are untenured or supervised by more senior members and thus not in a position to
          openly disagree.  Most concerning, this signing implies endorsement by USC itself. Thus, <em>we call on USC leadership
          to publicly rebuke the practice of USC departments (or units) making statements for specific political agendas
          that have nothing to do with the University’s educational and research missions</em>. The Statement above contains
          extreme, indeed fabricated, claims that criminalize the very creation of the State of Israel and, by
          implication, indict all its citizens and supporters, including us. Not doing so, would make USC complicit in
          comments within the Statement that describe the State of Israel as "settler colonialism", "ethnonationalist violence", "ongoing
          ethnic cleansing", and "apartheid".  If USC’s implicit support stands, many Jewish students and others who
          believe in Israel’s right to exist  will be reluctant to attend our university.
        </p>
        <p>
          Moreover, taking a one-sided position on such a complex issue as the Israeli/Palestinian conflict undermines
          the role of the University as an educational institution of open inquiry and thoughtful debate.  Instead of
          creating an environment of mutual respect, diversity, and inclusion that should characterize all academic
          discourse, proclamations of departmental political positions create an unwelcoming, even toxic, atmosphere
          for students who disagree with them. Without strong and clear disavowal by university leaders, students will
          assume that USC stands behind this specific Statement. Inaction by the university leadership on this
          important matter is particularly harmful in the current context: antisemitism is undeniably on the rise around
          the world, in our country, and on our campus [2]. Recent days have seen
          many <a href="https://combatantisemitism.org/latest-news/database-of-antisemitic-incidents-worldwide-since-outbreak-of-israel-gaza-violence/" target="_blank" rel="noopener noreferrer">recorded violent anti-Jewish
          incidents</a> [6], fueled by anti-Israeli rhetoric and the one-sided view on this complex issue exemplified by
          this Statement.
        </p>
        <p>
          It is particularly troubling in this departmental endorsement, where political disagreement is entangled
          with ethnic and religious identity.  Jewish students and anyone who supports the right of the State of Israel
          to exist and are enrolled in one of the endorsing department's classes might now expect, quite reasonably,
          that they are not welcome as peers and that their academic careers will be harmed because of their beliefs or
          identity.
        </p>
        <p>
          In the wake of the Rose Ritch affair, we have been promised that a series of activities will be
          implemented to improve our campus climate. We were hoping to see educational activities that aim to combat
          zionophobia and antisemitism, as well as other forms of hate and discrimination, to reaffirm our commitment
          to tolerance and inclusion, and to enable discussion of controversial issues in a respectful environment. We
          are still waiting for concrete actions from the administration.
        </p>
        <p>
          Consequently, we ask you to:
          (1) voice an official USC position on the content of the specific Statement referenced above, assure our
          community that it does not reflect an endorsement by USC, and require the department to rescind its support
          of the Statement;
          (2) take immediate concrete steps to prevent identity-based discrimination and harassment before students
          arrive on campus; and
          (3) clarify policies surrounding political endorsements and pronouncements made by departments.
        </p>

        <p>Respectfully,</p>

        <ul className="list-unstyled">
          <li>ROBERT ADLER, MD, MS.Ed., Chief Medical Officer, CHLA Health System, Senior Advisor to the Chair of Pediatrics</li>
          <li>LISA AZIZ-ZADEH, Associate Professor of Psychology and Occupational Science and Occupational Therapy</li>
          <li>ITZHAK BARS, Professor of Physics and Astronomy</li>
          <li>EYAL BEN-ISAAC, MD, Associate Professor of Clinical Pediatrics, Keck School of Medicine and  CHLA</li>
          <li>YEHUDA BEN-ZION, Professor of Earth Sciences, Director of Southern California Earthquake Center</li>
          <li>JACK M. BERGER, MS, MD, Ph.D, Professor Emeritus of Clinical Anesthesiology</li>
          <li>LISA M. BITEL, Dean's Professor of Religion and Professor of History</li>
          <li>RICHARD L. BRUTCHEY, Professor of Chemistry</li>
          <li>URI ELKAYAM, MD, Professor of Medicine and Obstetrics and Gynecology, Director of Maternal Cardiology</li>
          <li>SUSAN R. ESTRICH, Robert Kingsley Professor of Law and Political Science</li>
          <li>DION DICKMAN, Associate Professor of Biological Sciences</li>
          <li>ROSA DI FELICE, Associate Professor of Physics and Astronomy and Quantitative and Computational Biology</li>
          <li>HENRYK FLASHNER, Professor, Department of Aerospace and Mechanical Engineering</li>
          <li>ERIC M. FRIEDLANDER, Dean's Professor and Chair of Mathematics</li>
          <li>SUSAN FRIEDLANDER, Professor of Mathematics and Director of the Center for Applied Mathematical Sciences</li>
          <li>LEANA GOLUBCHIK, Stephen and Etta Varra Professor of Computer Science and Electrical and Computer Engineering</li>
          <li>MYRON F. GOODMAN, Professor of Biological Sciences and Chemistry</li>
          <li>MIKE GRUNTMAN, Professor of Astronautics</li>
          <li>LARRY HARRIS, Fred V. Keenan Chair in Finance, Marshall School of Business</li>
          <li>JEFFREY B. HIRSCH, Adjunct Professor, Annenberg School for Communications &amp; Journalism</li>
          <li>JUDITH A. HIRSCH, Gabilan Distinguished Professor in Science and Engineering and Professor of Biological Sciences</li>
          <li>ELLIS HOROWITZ, Professor of Computer Science &amp; Electrical Engineering</li>
          <li>GABRIEL KAHN, Professor of Professional Practice</li>
          <li>VITALY V. KRESIN,  Professor of Physics and Astronomy</li>
          <li>ANNA I. KRYLOV, Professor of Chemistry, Member, International Academy of Quantum Molecular Science, Foreign Member, Academia Europaea</li>
          <li>CLIVE LENNOX, USC Accounting Associates Professor of Accounting, Marshall School of Business, Leventhal School of Accounting</li>
          <li>PAT LEVITT, PhD, Chief Scientific Officer, Vice President and Director, The Saban Research Institute, Simms/Mann Chair in
            Developmental Neurogenetics, Children's Hospital Los Angeles, WM Keck Provost Professor of Neurogenetic, Keck School of Medicine</li>
          <li>EMILY R. LIMAN, Professor of Neurobiology</li>
          <li>RICHARD LUBMAN, MD, Associate Professor of Medicine</li>
          <li>GERARD MEDIONI, Professor Emeritus of Computer Science</li>
          <li>JERRY MENDEL, Emeritus Professor of Electrical Engineering</li>
          <li>M. SUSAN MONTGOMERY, Gabilan Distinguished Professor in Science and Engineering and Professor of Mathematics</li>
          <li>DAPHNA OYSERMAN, Dean’s Professor of Psychology</li>
          <li>RICHARD S. PANUSH, MD, Master, American College of Physicians, Master, American College of Rheumatology, Professor of Medicine</li>
          <li>DANIEL POLLACK, Thornton School of Music</li>
          <li>STEFAN POLLACK, Adjunct Professor, Annenberg School of Communications</li>
          <li>G. K. SURYA PRAKASH, Professor and George and Judith Olah Nobel Laureate Chair in Hydrocarbon Chemistry</li>
          <li>HANNA REISLER, Lloyd Armstrong Jr. Chair in Science and Engineering and Professor of Chemistry, Member, American Academy of Arts and Sciences</li>
          <li>MICHAEL RENOV, The Haskell Wexler Endowed Chair in Documentary, Professor of Cinema &amp; Media Studies, Vice Dean, Academic Affairs, School of Cinematic Arts</li>
          <li>STEVEN RICHEIMER, MD, Chief, Division of Pain Medicine, Professor of Anesthesiology &amp; Psychiatry</li>
          <li>REMO ROHS, Professor and Department Chair, Quantitative and Computational Biology</li>
          <li>DAN SCHNUR, Adjunct Professor, Annenberg School of Communications</li>
          <li>NORBERT SCHWARZ, Provost Professor,  Department of Psychology &amp; Marshall School of Business, Co-director, USC Dornsife Mind &amp; Society Center</li>
          <li>HAROLD SLAVKIN, Professor and Dean Emeritus, Herman Ostrow School of Dentistry</li>
          <li>DOUGLAS C. SOLOW, DDS, MBA, G. Donald Montgomery Professor of Dentistry, Associate Dean of Clinical Affairs, Director, Herman Ostrow School of Dentistry Faculty Practice</li>
          <li>WILLIAM STOHL, MD, PhD, Master, American College of Rheumatology, Professor of Medicine</li>
          <li>ANDREW VITERBI, Presidential Chair Professor of Electrical Engineering, Member, National Academy of Engineering,  Member, National Academy of Sciences</li>
          <li>CHERYL WAKSLAK, Associate Professor of Management and Organization &amp; McAlister Associate Professor in Business Administration</li>
          <li>ARIEH WARSHEL, Nobel Laureate, Dana and David Dornsife Chair in Chemistry, Member, National Academy of Sciences</li>
          <li>MICHAEL WATERMAN, University Professor Emeritus, Member, National Academy of Sciences, Member, National Academy of Engineering</li>
          <li>MARK WEINSTEIN, Associate Professor of Finance and Business Economics Emeritus</li>
          <li>RUTH WEISBERG, Professor, Roski School of Art and Design</li>
          <li>ALAN WILLNER, Steven and Kathryn Sample Chair in Engineering, Member, National Academy of Engineering</li>
          <li>REGINA WITTENBERG MOERMAN, Accounting Circle Professor of Business Administration, Marshall School of Business, Leventhal School of Accounting</li>
          <li>CURT WITTIG, Paul A. Miller Professor of Letters, Arts and Sciences</li>
          <li>VLADIMIR ZELMAN, MD, PhD, Clinical Professor of Anesthesiology, Foreign Member, Russian Academy of Science</li>
        </ul>
      </div>
    </Col>
  );
}

export default Petition;